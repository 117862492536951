import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { map, take } from 'rxjs';
import {
  BinaryOperatorEnum,
  Boolean,
  Comparison,
  ConditionPair,
  ConditionTypeEnum,
  DateAndTime,
  DateAndTimeDiapasonOperand,
  DictionaryTaskResult,
  FabDictionary,
  FabDictionaryItem,
  FirstClickResult,
  FirstGlanceResult,
  FloatingPoint,
  FloatingPointDiapasonOperand,
  Function,
  Integer,
  IntegerDiapasonOperand,
  MatrixResult,
  Operand,
  OperandEnum,
  ScaleResult,
  SessionOperand,
  SourceItem,
  TestItemConditionValue,
  TestItemConditionValueTypeEnum,
  TestItemOperand,
  TestItemResult,
  TestItemResultFieldEnum,
  TestItemResultStatusEnum,
  TestItemResultTypeEnum,
  TestItemStatisticsTypeEnum,
  TestItemTypeEnum,
  TestSessionEnvironmentFieldEnum,
  Text,
  TimeInterval,
  TimeIntervalDiapasonOperand,
} from 'src/api/testrunner/models';
import { LogicEnvironmentParams } from '../models/logic-environment-params';
import { LogicResultEnum } from '../models/logic-result-enum';
import { PipingItem } from '../models/piping-item';
import { TestItemModel } from '../models/test-item-model';
import { selectAllTestItemModelsOnPage } from '../store/selectors/page.selectors';
import { selectAllDictionaries } from '../store/selectors/test-runner.selectors';
import { AnswerUtilsService } from './answer-utils.service';
import { CompareHelperService, DiapasonValue, OperandInterface } from './compare-helper.service';
import { DateTimeService } from './date-time.service';
import { MacrosService } from './macros.service';
import { PersistenceService } from './persistence.service';

const answerLocalStoragePrefix: string = 'answer_';

enum TestItemResultStatusLogicEnum {
  HasAnswer = 'HasAnswer',
  IgnoredByUser = 'IgnoredByUser',
  SkippedByLogic = 'SkippedByLogic',
  Failed = 'Failed',
  TimeOut = 'TimeOut',
}

const ANSWER_STATUS_MAP: Map<TestItemResultStatusEnum, TestItemResultStatusLogicEnum> = new Map<
  TestItemResultStatusEnum,
  TestItemResultStatusLogicEnum
>([
  [TestItemResultStatusEnum.SubmittedByUser, TestItemResultStatusLogicEnum.HasAnswer],
  [TestItemResultStatusEnum.Intermediate, TestItemResultStatusLogicEnum.HasAnswer],
  [TestItemResultStatusEnum.IgnoredByUser, TestItemResultStatusLogicEnum.IgnoredByUser],
  [TestItemResultStatusEnum.SkippedByLogic, TestItemResultStatusLogicEnum.SkippedByLogic],
  [TestItemResultStatusEnum.Failed, TestItemResultStatusLogicEnum.Failed],
  [TestItemResultStatusEnum.TimeOut, TestItemResultStatusLogicEnum.TimeOut],
]);

@Injectable({
  providedIn: 'root',
})
export class LogicCalculationService {
  private _logicEnvironmentParams: LogicEnvironmentParams | null = null;

  constructor(
    private readonly answerUtilsService: AnswerUtilsService,
    private readonly macrosService: MacrosService,
    private readonly persistenceService: PersistenceService,
    private readonly store: Store,
  ) {}

  set logicEnvironmentParams(_logicEnvironmentParams: LogicEnvironmentParams | null) {
    this._logicEnvironmentParams = _logicEnvironmentParams;
  }

  /**
   * Вычисление логики
   */
  private calculateComparison(comparison: Comparison): LogicResultEnum {
    const isAnswerSkipped: boolean =
      this.persistenceService.get(answerLocalStoragePrefix + (comparison.operandLeft as any)?.sourceTestItemId)?.isAnswerSkipped ?? false;
    if (isAnswerSkipped) {
      return LogicResultEnum.logicTrue;
    }

    const [leftOperand, rightOperand] = [this.evalOperand(comparison.operandLeft), this.evalOperand(comparison.operandRight)];
    return CompareHelperService.compare(leftOperand, rightOperand, comparison.operator, comparison.negative);
  }

  private calculateFunction(func: Function, iterationId: string | null): LogicResultEnum {
    if (func?.script) {
      const sourceItem = this._logicEnvironmentParams?.testItems[func?.testItemId ?? '']
        ? ({
            ...this._logicEnvironmentParams?.testItems[func?.testItemId ?? ''],
            iterationId,
          } as PipingItem)
        : undefined;

      const functionResult = this.macrosService.evalScript(func.script, sourceItem) as boolean;
      return functionResult ? LogicResultEnum.logicTrue : !functionResult ? LogicResultEnum.logicFalse : LogicResultEnum.logicUndefined;
    }
    return LogicResultEnum.logicUndefined;
  }

  public calculateLogic(
    conditionPair: ConditionPair | null,
    iterationId: string | null,
    isLogicUndefinedIgnored: boolean,
  ): LogicResultEnum {
    if (conditionPair === null || (!conditionPair?.leftItem && !conditionPair?.rightItem)) {
      return LogicResultEnum.logicTrue;
    }

    let leftResult: LogicResultEnum | null = null;
    let rightResult: LogicResultEnum | null = null;

    if (conditionPair?.leftItem) {
      leftResult =
        conditionPair.leftItem?.testItemConditionType === ConditionTypeEnum.Item
          ? this.calculateComparison(conditionPair.leftItem as Comparison)
          : conditionPair.leftItem?.testItemConditionType === ConditionTypeEnum.Function
            ? this.calculateFunction(conditionPair.leftItem as Function, iterationId)
            : this.calculateLogic(conditionPair.leftItem as ConditionPair, iterationId, isLogicUndefinedIgnored);
    }

    if (conditionPair.rightItem) {
      rightResult =
        conditionPair.rightItem?.testItemConditionType === ConditionTypeEnum.Item
          ? this.calculateComparison(conditionPair.rightItem as Comparison)
          : conditionPair.rightItem?.testItemConditionType === ConditionTypeEnum.Function
            ? this.calculateFunction(conditionPair.rightItem as Function, iterationId)
            : this.calculateLogic(conditionPair.rightItem as ConditionPair, iterationId, isLogicUndefinedIgnored);
    }

    if (leftResult === null && rightResult === null) {
      return LogicResultEnum.logicTrue;
    }

    if (leftResult === null) {
      return isLogicUndefinedIgnored && rightResult === LogicResultEnum.logicUndefined
        ? LogicResultEnum.logicTrue
        : (rightResult as LogicResultEnum);
    }

    if (rightResult === null) {
      return isLogicUndefinedIgnored && leftResult === LogicResultEnum.logicUndefined
        ? LogicResultEnum.logicTrue
        : (leftResult as LogicResultEnum);
    }

    if (conditionPair.binaryOperator === BinaryOperatorEnum.And) {
      return rightResult === LogicResultEnum.logicTrue && leftResult === LogicResultEnum.logicTrue
        ? LogicResultEnum.logicTrue
        : LogicResultEnum.logicFalse;
    }

    if (conditionPair.binaryOperator === BinaryOperatorEnum.Or) {
      return rightResult === LogicResultEnum.logicTrue || leftResult === LogicResultEnum.logicTrue
        ? LogicResultEnum.logicTrue
        : LogicResultEnum.logicFalse;
    }

    return LogicResultEnum.logicUndefined;
  }

  public isIteratesItemInAnswer(targetAnswer: TestItemResult, iterationId: string): boolean {
    switch (targetAnswer.type) {
      case TestItemResultTypeEnum.FirstClick:
        return this.answerUtilsService
          .getClickAreas(
            {
              x: (targetAnswer as FirstClickResult).cursorPositionX ?? -1,
              y: (targetAnswer as FirstClickResult).cursorPositionY ?? -1,
            },
            targetAnswer?.testItemId ?? '',
          )
          .some(({ fabDictionaryEntityId }) => fabDictionaryEntityId === iterationId);
      case TestItemResultTypeEnum.Matrix:
        return ((targetAnswer as MatrixResult)?.elementItems ?? []).some(
          ({ columnElementItemId, rowElementItemId }) => columnElementItemId === iterationId || rowElementItemId === iterationId,
        );
      case TestItemResultTypeEnum.Scale:
        return ((targetAnswer as ScaleResult)?.resultItems ?? []).some(({ elementItemId }) => elementItemId === iterationId);
      case TestItemResultTypeEnum.Select:
      case TestItemResultTypeEnum.Ranking:
        return ((targetAnswer as DictionaryTaskResult)?.elementRowItemIds ?? []).includes(iterationId ?? '');
      default:
        return false;
    }
  }

  /**
   * Приведение значений оператора к массиву примитивных типов для сравнения
   */
  private evalOperand(operand: Operand): OperandInterface | null {
    switch (operand.operandType) {
      case OperandEnum.Const: {
        const values = this.getOperandValues(operand.values ?? []);
        return {
          isArray:
            values.length > 1 || (operand.values || []).some((value) => value.valueType === TestItemConditionValueTypeEnum.SourceItem),
          values,
          periodValue: null,
        } as OperandInterface;
      }
      case OperandEnum.TestItemEnvironment: {
        return this.getTestItemOperandValues(operand as TestItemOperand);
      }
      case OperandEnum.TestSessionEnvironment: {
        return this.getSessionOperandValues(operand as SessionOperand);
      }
      case OperandEnum.ConstDiapasonDateAndTime:
      case OperandEnum.ConstDiapasonTimeInterval:
      case OperandEnum.ConstDiapasonInteger:
      case OperandEnum.ConstDiapasonFloatingPoint: {
        const diapasonOperand = operand as
          | DateAndTimeDiapasonOperand
          | TimeIntervalDiapasonOperand
          | IntegerDiapasonOperand
          | FloatingPointDiapasonOperand;
        return {
          isArray: false,
          values: [],
          periodValue: {
            from: diapasonOperand?.from ? (this.getOperandValues([diapasonOperand.from])[0] as number) : null,
            to: diapasonOperand?.to ? (this.getOperandValues([diapasonOperand.to])[0] as number) : null,
          } as DiapasonValue,
        } as OperandInterface;
      }
      default: {
        return null;
      }
    }
  }

  /**
   * Получение значений константного операнда
   */
  private getOperandValues(
    values: Array<
      // eslint-disable-next-line @typescript-eslint/ban-types,id-blacklist
      TestItemConditionValue | DateAndTime | FloatingPoint | Integer | SourceItem | Text | TimeInterval | Boolean
    >,
  ): (string | number | boolean)[] {
    const resultValues: (string | number | boolean)[] = [];
    values.forEach((val) => {
      if ('dateTimeValue' in val) {
        resultValues.push(DateTimeService.getTimestampUTC(val.dateTimeValue));
      }
      if ('doubleValue' in val) {
        resultValues.push(val.doubleValue);
      }
      if ('int64Value' in val) {
        resultValues.push(val.int64Value);
      }
      if ('stringValue' in val) {
        resultValues.push(val.stringValue);
      }
      if ('timeSpanValue' in val) {
        resultValues.push(DateTimeService.convertTimeToMilliseconds(val.timeSpanValue));
      }
      if ('sourceItemId' in val) {
        resultValues.push(val.sourceItemId);
      }
      if ('boolValue' in val) {
        resultValues.push(val.boolValue);
      }
    });
    return resultValues;
  }

  /**
   * Получение значений операнда тест айтемов
   */
  private getTestItemOperandValues(operand: TestItemOperand): OperandInterface {
    const answer: TestItemResult | null = this.persistenceService.get(answerLocalStoragePrefix + operand.sourceTestItemId);
    let resultValues: (string | number | boolean)[] = [];
    if (answer) {
      switch (operand.testItemResultField) {
        case TestItemResultFieldEnum.TestItemAnswerStatus: {
          const status: TestItemResultStatusEnum | undefined = answer.status;
          if (isNil(status)) {
            resultValues = [];
            break;
          }
          const model: TestItemModel | null = this.getTestItemModelSync(operand.sourceTestItemId);
          if (isNil(model)) {
            resultValues = [];
            break;
          }
          const dictionaryItems: FabDictionaryItem[] = this.getDictionaryItems(model);
          const isValid: boolean = this.answerUtilsService.isAnswerValid(model, dictionaryItems, true);
          const answerStatus: TestItemResultStatusLogicEnum | undefined = ANSWER_STATUS_MAP.get(status);

          if (answerStatus === TestItemResultStatusLogicEnum.HasAnswer && !isValid) {
            resultValues = [];
            break;
          }
          resultValues = isNil(answerStatus) ? [] : [answerStatus];
          break;
        }
        case TestItemResultFieldEnum.TestItemResult: {
          resultValues = this.answerUtilsService.getAnswerValues(answer, false, operand?.sourceTestItemRowDictionaryItemId ?? '');
          break;
        }
        case TestItemResultFieldEnum.TestItemCustomAnswer: {
          resultValues = this.answerUtilsService.getAnswerValues(answer, true, operand?.sourceTestItemRowDictionaryItemId ?? '');
          break;
        }
        case TestItemResultFieldEnum.TestItemIsAnswerCorrect: {
          const isRightAnswer = this.answerUtilsService.isRightAnswer(answer);
          resultValues = isRightAnswer !== null ? [isRightAnswer] : [];
          break;
        }
        case TestItemResultFieldEnum.TestItemIsAnswerPartialCorrect: {
          const isRightAnswer = this.answerUtilsService.isRightAnswer(answer, true);
          resultValues = isRightAnswer !== null ? [isRightAnswer] : [];
          break;
        }
        case TestItemResultFieldEnum.TestItemStartTime: {
          resultValues = answer?.clientStartTimeUtc ? [DateTimeService.getTimestampUTC(answer.clientStartTimeUtc)] : [];
          break;
        }
        case TestItemResultFieldEnum.TestItemAnswerTime: {
          resultValues = answer?.clientEndTimeUtc ? [DateTimeService.getTimestampUTC(answer.clientEndTimeUtc)] : [];
          break;
        }
        case TestItemResultFieldEnum.TestItemDuration: {
          resultValues = answer?.answerTimeSpentMs
            ? [answer.answerTimeSpentMs]
            : [answer?.clientStartTimeUtc ? DateTimeService.getDuration(answer?.clientStartTimeUtc) : 0];
          break;
        }
        case TestItemResultFieldEnum.TestItemReadingTimeSpent: {
          resultValues =
            'readingTimeSpentMs' in answer && (answer as FirstClickResult | FirstGlanceResult)?.readingTimeSpentMs
              ? [(answer as FirstClickResult | FirstGlanceResult).readingTimeSpentMs ?? 0]
              : [answer?.clientStartTimeUtc ? DateTimeService.getDuration(answer?.clientStartTimeUtc) : 0];
          break;
        }
        case TestItemResultFieldEnum.LogicResultTrueCount: {
          return this.getTestItemStatisticsOperand(operand.sourceTestItemId ?? '', TestItemStatisticsTypeEnum.LogicResultTrueAndCompleted);
        }
        case TestItemResultFieldEnum.LogicResultFalseCount: {
          return this.getTestItemStatisticsOperand(operand.sourceTestItemId ?? '', TestItemStatisticsTypeEnum.LogicResultFalseAndCompleted);
        }
      }
    }

    return {
      isArray: resultValues.length > 1,
      periodValue: null,
      values: resultValues,
    } as OperandInterface;
  }

  /**
   * Получение значений сессионного операнда
   */
  private getSessionOperandValues(operand: SessionOperand): OperandInterface {
    const resultValues: (string | number | boolean)[] = [];

    switch (operand.testSessionEnvField) {
      case TestSessionEnvironmentFieldEnum.ScreenWidth: {
        resultValues.push(window.innerWidth);
        break;
      }
      case TestSessionEnvironmentFieldEnum.ScreenHeight: {
        resultValues.push(window.innerHeight);
        break;
      }
      case TestSessionEnvironmentFieldEnum.TestStartTime: {
        resultValues.push(this._logicEnvironmentParams?.testInfo?.testStartTimestampUTC ?? 0);
        break;
      }
      case TestSessionEnvironmentFieldEnum.TestDuration: {
        resultValues.push(
          this._logicEnvironmentParams?.testInfo?.testStartTimestampUTC
            ? DateTimeService.currentTimestampUTC - this._logicEnvironmentParams?.testInfo?.testStartTimestampUTC
            : 0,
        );
        break;
      }
      case TestSessionEnvironmentFieldEnum.Browser: {
        resultValues.push(this._logicEnvironmentParams?.browserInfo?.entityId ?? '');
        break;
      }
      case TestSessionEnvironmentFieldEnum.BrowserVersion: {
        resultValues.push(this._logicEnvironmentParams?.testRunnerStartData?.browserVersion ?? '');
        break;
      }
      case TestSessionEnvironmentFieldEnum.IsMobileDevice: {
        if (
          this._logicEnvironmentParams?.testRunnerStartData?.isMobileDevice !== undefined &&
          this._logicEnvironmentParams?.testRunnerStartData?.isMobileDevice !== null
        ) {
          resultValues.push(this._logicEnvironmentParams?.testRunnerStartData.isMobileDevice);
        }
        break;
      }
      case TestSessionEnvironmentFieldEnum.Os: {
        resultValues.push(this._logicEnvironmentParams?.osInfo?.entityId ?? '');
        break;
      }
      case TestSessionEnvironmentFieldEnum.QueryString: {
        resultValues.push(this._logicEnvironmentParams?.testRunnerStartData?.query ?? '');
        break;
      }
    }

    return {
      isArray: resultValues.length > 1,
      values: resultValues,
      periodValue: null,
    } as OperandInterface;
  }

  private getTestItemStatisticsOperand(testItemId: string, key: TestItemStatisticsTypeEnum): OperandInterface {
    const resultValues: (string | number | boolean)[] = (this._logicEnvironmentParams?.testItemStatistics || [])
      .filter((item) => item?.type === key && item?.testItemId === testItemId)
      .map((item) => item?.count ?? 0);
    return {
      isArray: resultValues.length > 1,
      periodValue: null,
      values: resultValues.length > 0 ? resultValues : [0],
    } as OperandInterface;
  }

  private getDictionaryItems(testItemModel: TestItemModel): FabDictionaryItem[] {
    const dictionaries: FabDictionary[] = this.getDictionariesSync();
    return [TestItemTypeEnum.CardSorting, TestItemTypeEnum.Matrix, TestItemTypeEnum.Ranking, TestItemTypeEnum.Scale].includes(
      testItemModel?.data?.testItemType,
    )
      ? (dictionaries.find((item: any) => testItemModel?.data?.rowFabDictionaryId === item.id)?.fabDictionaryItems ?? [])
      : [];
  }

  private getDictionariesSync(): FabDictionary[] {
    // refactor in any case (don't have opportunity to make it async)
    let data: any;
    this.store
      .select(selectAllDictionaries)
      .pipe(take(1))
      .subscribe((v) => (data = v));
    return data;
  }

  private getTestItemModelSync(idToFind: any): TestItemModel | null {
    // refactor in any case (don't have opportunity to make it async)
    let data: any;
    this.store
      .select(selectAllTestItemModelsOnPage)
      .pipe(
        take(1),
        map((models: TestItemModel[]) => models.find(({ id }: TestItemModel) => id === idToFind)),
      )
      .subscribe((model: TestItemModel | undefined) => (data = model ?? null));
    return data;
  }
}
