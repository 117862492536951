<ng-template
  [ngTemplateOutlet]="pageTmpl"
  [ngTemplateOutletContext]="{
    isMobileInProgress: isMobileInProgress$ | async,
    isWebSiteInProgress: isWebSiteInProgress$ | async,
    delayBeforeAnswer: delayBeforeAnswer$ | async,
    answerTimeLimit: answerTimeLimit$ | async,
  }"
></ng-template>

<ng-template
  #pageTmpl
  let-isMobileInProgress="isMobileInProgress"
  let-isWebSiteInProgress="isWebSiteInProgress"
  let-delayBeforeAnswer="delayBeforeAnswer"
  let-answerTimeLimit="answerTimeLimit"
>
  <ng-container *transloco="let t">
    <!--  <div *ngIf="!(testItemModels$ | async)?.length">
        <p-progressSpinner></p-progressSpinner>
        <br>
        <p>{{ t('testrunner.page.pageWaiting') }}...</p>
      </div>-->
    <!--@TODO-->
    <!--<app-kano [kano]="kano"></app-kano>-->

    <app-kano
      *ngIf="page.testItemType === testItemTypes.Kano; else mobileWeb"
      [kano]="page"
      (answerChange)="onAnswerChanges($event)"
      (finish)="goToNextPage()"
    ></app-kano>

    <ng-template #mobileWeb>
      <ng-container *ngIf="isMobileInProgress || isWebSiteInProgress; else elseBlock">
        <app-mobile
          *ngIf="isMobileInProgress"
          class="flex"
          [mobile]="page"
          [testInfo]="testInfo$ | async"
          [canStartTask]="delayBeforeAnswer <= 0"
        ></app-mobile>
        <app-web-site
          *ngIf="isWebSiteInProgress"
          (skipTask)="onSkipTask()"
          [webSite]="page"
          [canStartTask]="delayBeforeAnswer <= 0"
        ></app-web-site>
      </ng-container>
    </ng-template>

    <ng-template #elseBlock>
      <div *ngFor="let item of testItemModels$ | async; let i = index; trackBy: trackByFn" class="pb-5 md:py-6" [hidden]="!item.isShown">
        <ng-container [ngSwitch]="item.data.testItemType">
          <app-card-sorting
            *ngSwitchCase="testItemTypes.CardSorting"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-card-sorting>
          <app-first-glance
            *ngSwitchCase="testItemTypes.FirstGlance"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-first-glance>
          <app-first-click
            *ngSwitchCase="testItemTypes.FirstClick"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-first-click>
          <app-free-form
            *ngSwitchCase="testItemTypes.FreeForm"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-free-form>
          <app-file
            *ngSwitchCase="testItemTypes.File"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-file>
          <app-info
            *ngSwitchCase="testItemTypes.Info"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-info>
          <app-matrix
            *ngSwitchCase="testItemTypes.Matrix"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-matrix>
          <app-number
            *ngSwitchCase="testItemTypes.Number"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-number>
          <app-ranking
            *ngSwitchCase="testItemTypes.Ranking"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-ranking>
          <app-scale
            *ngSwitchCase="testItemTypes.Scale"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-scale>
          <app-select
            *ngSwitchCase="testItemTypes.Select"
            appStepContainerClassList
            [testItemModel]="item"
            [index]="i"
            (answerChanges)="onAnswerChanges($event)"
          ></app-select>
        </ng-container>
      </div>
      <div class="flex justify-content-end pb-5 mt-3 md:mt-8">
        <button
          pButton
          pRipple
          type="button"
          class="p-button-success p-button-sm mr-2 mb-2"
          (click)="goToPrevPage()"
          [disabled]="delayBeforeAnswer > 0"
          [class.hidden]="(isBackButtonEnabled$ | async) === false"
          [label]="backButtonText"
          icon="fa-regular fa-left-long"
          data-cy="back-button"
        ></button>
        <button
          *ngIf="page.testItemType === testItemTypes.WebSite"
          pButton
          pRipple
          type="button"
          class="flex justify-content-center ml-2 mr-2 mb-2 p-button-outlined p-button-secondary"
          icon="fa-regular fa-left-long"
          (click)="returnToTask()"
          [label]="returnButtonText"
        ></button>
        <button
          pButton
          pRipple
          type="button"
          class="p-button-success p-button-sm mb-2"
          [class.disabled-click]="isBtnClickDisabled"
          [disabled]="(isAnswersValid$ | async) === false || delayBeforeAnswer > 0"
          (click)="goToNextPage()"
          [label]="page.testItemType !== testItemTypes.WebSite ? nextButtonText : finishWebSiteButtonText"
          [icon]="page.testItemType !== testItemTypes.WebSite ? 'fa-regular fa-right-long' : ''"
          iconPos="right"
          data-cy="next-button"
        ></button>
      </div>
    </ng-template>

    <div *ngIf="page.delayBeforeAnswerMS && delayBeforeAnswer > 0" [class.hidden]="!page.timerDisplayEnabled">
      {{ t('testrunner.page.delayBeforeAnswer') }} {{ delayBeforeAnswer | timerFormat }} {{ t('testrunner.page.seconds') }}
    </div>
    <div *ngIf="page.answerTimeLimitEnabled && answerTimeLimit > 0" [class.hidden]="delayBeforeAnswer > 0 || !page.timerDisplayEnabled">
      {{ t('testrunner.page.answerTimeLimit') }} {{ answerTimeLimit | timerFormat }} {{ t('testrunner.page.seconds') }}
    </div>
  </ng-container>
</ng-template>
