import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { delay, exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { LogicService } from 'src/app/services/logic.service';
import { IfCompleteResultStatusEnum, TestItemTypeEnum } from '../../../api/testrunner/models';
import { EMPTY_GUID } from '../../constants/constants';
import { CompleteTestInfo } from '../../models/complete-test-info';
import { IteratorInfo } from '../../models/iterator-info';
import { MobileAppActionEnum } from '../../models/mobile-app-action-enum';
import { PipingItem } from '../../models/piping-item';
import { TestInfo } from '../../models/test-info';
import { TestStatusEnum } from '../../models/test-status.enum';
import { Video } from '../../models/video';
import { WebSiteActionEnum } from '../../models/web-site-action-enum';
import { AnswerUtilsService } from '../../services/answer-utils.service';
import { DateTimeService } from '../../services/date-time.service';
import { MobileService } from '../../services/mobile.service';
import { PersistenceService } from '../../services/persistence.service';
import { VideoRecorderService } from '../../services/video-recorder.service';
import { WebSiteService } from '../../services/web-site.service';
import {
  getCurrentPageAction,
  getCurrentPageSuccessAction,
  getNextPageAction,
  getNextPageSuccessAction,
  getPrevPageAction,
  getPrevPageSuccessAction,
  getTestItemModelsVisibilitySuccessAction,
  initPageSuccessAction,
  revalidatePageAnswersSuccessAction,
} from '../actions/page.actions';
import { saveVideoAction } from '../actions/telemetry.actions';
import {
  getBalancingStatisticsSuccessAction,
  getLayoutStatisticsSuccessAction,
  getPageItemsStatisticsSuccessAction,
} from '../actions/test-item-statistics.actions';
import { finishTestAction, screenOutAction, setSessionCacheAction } from '../actions/test-runner.actions';
import { selectAllTestItemModelsOnPage, selectCurrentPage, selectPreviousPage } from '../selectors/page.selectors';
import { selectTestItemById } from '../selectors/test-items.selectors';
import { selectAllDictionaries, selectLogicEnvironment, selectTestInfo } from '../selectors/test-runner.selectors';

@Injectable()
export class PageEffects {
  /**
   * Инициаоизация состояния страницы в сторе
   */
  initPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getBalancingStatisticsSuccessAction, getPrevPageSuccessAction, getCurrentPageSuccessAction),
      concatLatestFrom(() => [this.store.select(selectLogicEnvironment)]),
      map(([{ page }, logicEnvironmentParams]) => {
        this.answerUtilsService.testItems = logicEnvironmentParams.testItems;
        this.logicService.resetGlobParams(logicEnvironmentParams);
        const testItemModels = page ? this.logicService.getAllTestItemsInPage(page) : [];
        return initPageSuccessAction({
          page,
          answers: this.logicService.answers,
          testItemModels,
          pageShowStartTime: DateTimeService.currentDateTimeUTC,
        });
      }),
    );
  });

  /**
   * Поиск и инициализация в сторе текущей страницы
   */
  getCurrentPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCurrentPageAction),
      switchMap(() => this.store.select(selectTestInfo)),
      concatLatestFrom((testInfo) => this.store.select(selectTestItemById(testInfo?.currentPageId ?? ''))),
      map(([testInfo, page]) =>
        getCurrentPageSuccessAction({
          page: page
            ? ({
                ...page,
                ...(testInfo?.currentIteratorInfo ?? undefined),
              } as PipingItem)
            : null,
          testInfo,
        }),
      ),
    );
  });

  /**
   * Поиск следующей страницы
   */
  getNextPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getLayoutStatisticsSuccessAction),
      delay(300),
      concatLatestFrom(() => [this.store.select(selectLogicEnvironment)]),
      map(([, logicEnvironmentParams]) => {
        let testInfo = logicEnvironmentParams.testInfo;
        const testRunnerStartData = logicEnvironmentParams.testRunnerStartData;
        const completeTestInfo = {
          completeStatusType: IfCompleteResultStatusEnum.Finished,
          completeContent: testRunnerStartData?.test?.finishContent ?? '',
          testId: testRunnerStartData?.test?.id ?? '',
        } as CompleteTestInfo;

        if (!testInfo) {
          this.persistenceService.set('finishSession_' + testRunnerStartData?.test?.id, completeTestInfo);
          this.persistenceService.set('redirectUrl_' + testRunnerStartData?.test?.id, testRunnerStartData?.test?.finishUrl ?? null);
          return finishTestAction({
            testInfo,
            completeTestInfo,
            url: testRunnerStartData?.test?.finishUrl ?? null,
          });
        }

        this.logicService.resetGlobParams(logicEnvironmentParams);
        const nextPage = this.logicService.getNextPage(testRunnerStartData?.test?.rootGroup?.groupItems ?? []);
        const screenOutItem = this.logicService.screenOutItem;
        const isTestInProgress = nextPage && screenOutItem === null;
        const prevPageId =
          isTestInProgress && testInfo?.currentPageId
            ? testInfo.currentPageId + (testInfo?.currentIteratorInfo?.iterationId ? '&' + testInfo.currentIteratorInfo.iterationId : '')
            : null;

        const iteratorInfo = (nextPage as PipingItem)?.iterationId
          ? ({
              ...(nextPage as PipingItem),
            } as IteratorInfo)
          : null;

        testInfo = {
          ...testInfo,
          currentPageId: isTestInProgress ? (nextPage?.id ?? null) : null,
          currentIteratorInfo: isTestInProgress ? iteratorInfo : null,
          status: isTestInProgress ? TestStatusEnum.InProgress : TestStatusEnum.Completed,
          stack: prevPageId !== null ? [...testInfo.stack, prevPageId] : [],
          testStartTimestampUTC: testInfo.testStartTimestampUTC ?? DateTimeService.currentTimestampUTC,
        };

        if (isTestInProgress) {
          return getNextPageSuccessAction({ page: nextPage, testInfo, answers: this.logicService.answers });
        } else {
          if (screenOutItem) {
            return screenOutAction({ screenOutItem, testInfo, answers: this.logicService.answers });
          } else {
            this.persistenceService.set('finishSession_' + testRunnerStartData?.test?.id, completeTestInfo);
            this.persistenceService.set('redirectUrl_' + testRunnerStartData?.test?.id, testRunnerStartData?.test?.finishUrl ?? null);
            return finishTestAction({
              answers: this.logicService.answers,
              testInfo,
              completeTestInfo,
              url: testRunnerStartData?.test?.finishUrl ?? null,
            });
          }
        }
      }),
    );
  });

  /**
   * Получение предыдущей страницы
   */
  getPrevPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPrevPageAction),
      delay(300),
      concatLatestFrom(() => this.store.select(selectTestInfo)),
      map(([, testInfo]) => {
        if (!testInfo) {
          return null;
        }
        const stack = [...(testInfo?.stack ?? [])];
        const [prevPageId, iteratorId] = stack.pop()?.split('&') ?? [];
        return {
          ...testInfo,
          currentPageId: prevPageId ?? null,
          currentIteratorId: iteratorId ?? null,
          status: TestStatusEnum.InProgress,
          stack,
        } as TestInfo;
      }),
      concatLatestFrom((testInfo) => this.store.select(selectTestItemById(testInfo?.currentPageId ?? ''))),
      map(([testInfo, page]) =>
        getPrevPageSuccessAction({
          page: page
            ? ({
                ...page,
                ...(testInfo?.currentIteratorInfo ?? undefined),
              } as PipingItem)
            : null,
          testInfo: testInfo ?? null,
        }),
      ),
    );
  });

  /**
   * Обновление визибилити тест айтемов
   */
  getTestItemModelsVisibility$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPageItemsStatisticsSuccessAction),
      concatLatestFrom(() => [this.store.select(selectCurrentPage), this.store.select(selectLogicEnvironment)]),
      map(([, currentPage, logicEnvironmentParams]) => {
        const testInfo = logicEnvironmentParams.testInfo;
        this.logicService.resetGlobParams(logicEnvironmentParams);
        const testItemModels = this.logicService.getTestItemModelsVisibility(
          currentPage?.groupItems || [],
          true,
          (currentPage as PipingItem)?.iterationId ?? null,
        );
        const screenOutItem = this.logicService.screenOutItem;
        if (screenOutItem === null) {
          return getTestItemModelsVisibilitySuccessAction({ testItemModels, answers: this.logicService.answers });
        } else {
          return screenOutAction({
            screenOutItem,
            testInfo: {
              ...testInfo,
              currentPageId: null,
              status: TestStatusEnum.Completed,
              stack: [],
            } as TestInfo,
          });
        }
      }),
    );
  });

  /*/!**
   * Пересчет ордера у страниц
   *!/
  reorderLayoutItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCurrentPageSuccessAction, getNextPageSuccessAction),
      concatLatestFrom(() => [this.store.select(selectLayoutGroups), this.store.select(selectAllTestItems)]),
      map(([, layoutGroups, layoutItems]) => {
        const reorderedItems = new Array<TestItem>();

        layoutGroups.forEach((layoutGroup) => {
          const shuffledOrder = this.persistenceService.get('random_' + layoutGroup?.id);
          const childrenItems = layoutItems.filter(
            (layoutItem) =>
              layoutItem.parentGroupId && layoutItem?.parentGroupId === layoutGroup?.id && layoutItem.testItemType !== TestItemTypeEnum.Else
          );
          if (shuffledOrder?.length && childrenItems?.length) {
            const skippedItems = childrenItems.filter((layoutItem) => !shuffledOrder.includes(layoutItem.id));
            const shuffledItems = childrenItems
              .filter((layoutItem) => shuffledOrder.includes(layoutItem.id))
              .sort((a, b) => shuffledOrder.indexOf(a.id) - shuffledOrder.indexOf(b.id));
            console.log(shuffledItems, skippedItems);
            reorderedItems.push(
              ...[...shuffledItems, ...skippedItems].map((layoutItem, index) => ({
                ...layoutItem,
                order: index,
              }))
            );
          }
        });
        console.log(reorderedItems);
        return reorderedItems;
      }),
      map((reorderedItems) => reorderLayoutItems({ reorderedItems }))
    );
  });*/

  /**
   * Проверка заполненности всех обязательных полей. (В будующем можно дополнить другими валидаторами форм)
   */
  revalidatePageAnswers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTestItemModelsVisibilitySuccessAction),
      exhaustMap(() => this.store.select(selectAllTestItemModelsOnPage)),
      concatLatestFrom(() => this.store.select(selectAllDictionaries)),
      map(([testItemModels, dictionaries]) =>
        testItemModels.reduce((isAllAnswersValid, testItemModel) => {
          const dictionaryItems = [
            TestItemTypeEnum.CardSorting,
            TestItemTypeEnum.Matrix,
            TestItemTypeEnum.Ranking,
            TestItemTypeEnum.Scale,
          ].includes(testItemModel?.data?.testItemType)
            ? (dictionaries.find((item) => testItemModel?.data?.rowFabDictionaryId === item.id)?.fabDictionaryItems ?? [])
            : [];
          const isValid = this.answerUtilsService.isAnswerValid(testItemModel, dictionaryItems);
          return isAllAnswersValid && isValid;
        }, true),
      ),
      map((isAnswersValid) => revalidatePageAnswersSuccessAction({ isAnswersValid })),
    );
  });

  /**
   * Сохранение локалстораджа на бэк и отправка постмэссаджа о завершении мобильного шага, пока только для Мобильного шага
   */
  shareLocalStorage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initPageSuccessAction),
      concatLatestFrom(() => this.store.select(selectPreviousPage)),
      tap(([{ page }, prevPage]) => {
        if (prevPage && page && TestItemTypeEnum.Mobile === prevPage?.testItemType) {
          this.mobileService.postMessage(MobileAppActionEnum.FinishTask, prevPage);
        }
      }),
      filter(([{ page }]) => page?.testItemType === TestItemTypeEnum.Mobile),
      map(([{ page }]) => {
        //Нужно сбрасывать в false, чтобы не кэшировался флаг
        /*if (this.persistenceService.get('isMobileAppInstalled') === null) {
          this.persistenceService.set('isMobileAppInstalled', false);
        }*/
        return setSessionCacheAction({ testId: page?.testId ?? '' });
      }),
    );
  });

  /**
   * Обработка завершения вебшага
   */
  stopVideoRecording$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getNextPageAction),
      concatLatestFrom(() => [
        this.store.select(selectCurrentPage),
        this.store.select(selectTestInfo),
        this.store.select(selectAllTestItemModelsOnPage),
      ]),
      filter(([, page]) => {
        console.log(page);
        return page?.testItemType === TestItemTypeEnum.WebSite;
      }),
      switchMap(([, page, testInfo, allTestItemModels]) => {
        this.webSiteService.postMessage(WebSiteActionEnum.FinishTask);
        console.log('Website extension finish task');
        return this.videoRecorderService.getVideo$(testInfo ?? null, page?.id, allTestItemModels[0]?.iterationId ?? EMPTY_GUID);
      }),
      filter((video) => !!video),
      tap((video) => console.log(video)),
      map((video) => saveVideoAction({ video: video as Video })),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly answerUtilsService: AnswerUtilsService,
    private readonly logicService: LogicService,
    private readonly mobileService: MobileService,
    private readonly persistenceService: PersistenceService,
    private readonly store: Store,
    private readonly videoRecorderService: VideoRecorderService,
    private readonly webSiteService: WebSiteService,
  ) {}
}
