import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject, Subscription, combineLatest, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { FabDictionaryItem } from 'src/api/testrunner/models';
import { TestItemModel } from 'src/app/models/test-item-model';
import { AnswerUtilsService } from 'src/app/services/answer-utils.service';
import { selectDictionaryItemsById } from 'src/app/store/selectors/test-runner.selectors';

@Directive({
  selector: '[appStepContainerClassList]',
  standalone: true,
})
export class StepContainerClasslistDirective {
  @Input({ required: true }) public set testItemModel(value: TestItemModel) {
    this.testItemModel$.next(value);
  }

  private readonly testItemModel$: ReplaySubject<TestItemModel> = new ReplaySubject<TestItemModel>(1);

  private readonly shortId$: Observable<string> = this.testItemModel$.pipe(
    map(({ data: { shortId } }: TestItemModel) => String(shortId ?? '')),
    distinctUntilChanged(),
  );

  private readonly dictionaries$: Observable<FabDictionaryItem[]> = this.testItemModel$.pipe(
    map(({ data: { rowFabDictionaryId } }: TestItemModel) => rowFabDictionaryId ?? ''),
    distinctUntilChanged(),
    switchMap((rowFabDictionaryId: string) => this.store.select(selectDictionaryItemsById(rowFabDictionaryId))),
  );

  private readonly isAnswerValid$: Observable<boolean> = combineLatest([this.testItemModel$, this.dictionaries$]).pipe(
    map(([testItemModel, dictionaries]: [TestItemModel, FabDictionaryItem[]]) =>
      this.answerUtilsService.isAnswerValid(testItemModel, dictionaries),
    ),
    debounceTime(100),
    distinctUntilChanged(),
  );

  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly renderer2: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly store: Store,
    private readonly answerUtilsService: AnswerUtilsService,
  ) {
    this.addBaseClasses();
    this.subscription.add(this.processShortIdChanges());
    this.subscription.add(this.processIsAnswerValidChanges());
  }

  private addBaseClasses(): void {
    this.renderer2.addClass(this.elementRef.nativeElement, 'q');
  }

  private processIsAnswerValidChanges(): Subscription {
    return this.isAnswerValid$.subscribe((isAnswerValid: boolean) => {
      isAnswerValid
        ? this.renderer2.addClass(this.elementRef.nativeElement, 'done')
        : this.renderer2.removeClass(this.elementRef.nativeElement, 'done');
    });
  }

  private processShortIdChanges(): Subscription {
    return this.shortId$.subscribe((shortId: string) => {
      this.renderer2.addClass(this.elementRef.nativeElement, 'quid' + shortId);
    });
  }
}
